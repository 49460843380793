body {
    font-family: 'Arial', sans-serif;
    background-color: #121212;
    color: #E0E0E0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .dashboard-container {
    background-color: #1E1E1E;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #BB86FC;
  }
  
  .visitors-count {
    font-size: 1.5rem;
    margin-top: 1rem;
    color: #E0E0E0;
  }
  