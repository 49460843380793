.projects-container {
    background-color: #1E1E1E;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    margin: auto;
}

.search-bar {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    box-sizing: border-box;
}

.project-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.project-card {
    background-color: #333;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.project-card h3 {
    margin: 0;
    color: #BB86FC;
}

.project-card img {
    width: 100%; /* Full width by default */
    height: auto;
    border-radius: 10px;
    margin-top: 0.5rem;
    transition: width 0.3s ease;
}

.project-card.expanded img {
    width: 100%; /* Full width when expanded */
}

.project-card .project-details {
    margin-top: 1rem;
}

.tag-list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.tag {
    background-color: #444;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.github-link {
    display: inline-block;
    margin-top: 1rem;
    color: #BB86FC;
    text-decoration: none;
}

.github-link:hover {
    text-decoration: underline;
}
