body {
    font-family: 'Arial', sans-serif;
    background-color: #121212;
    color: #E0E0E0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  li {
    padding-left:2rem;
  }
  .resume-container {
    background-color: #1E1E1E;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .profile-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .profile-section h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #BB86FC;
  }
  
  .profile-section p {
    font-size: 1.25rem;
    color: #E0E0E0;
  }
  
  .contact-section,
  .experience-section,
  .education-section,
  .skills-section {
    margin-bottom: 2rem;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #BB86FC;
    margin-bottom: 1rem;
  }
  
  p {
    margin: 0.5rem 0;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  li {
    margin-bottom: 0.5rem;
  }
  
  .skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .skills-list li {
    background-color: #333;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  