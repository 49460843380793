.camera-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .webcam {
    border: 2px solid #333;
    border-radius: 10px;
  }
  
  .button-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .camera-button {
    padding: 0.75rem;
    border-radius: 5px;
    border: none;
    background-color: #BB86FC;
    color: #121212;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .camera-button:hover {
    background-color: #3700B3;
  }
  
  .screenshot {
    margin-top: 1rem;
    border: 2px solid #333;
    border-radius: 10px;
    width: 300px;
  }
  