body {
    font-family: 'Arial', sans-serif;
    background-color: #121212;
    color: #E0E0E0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: #1E1E1E;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .camera {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    border: 2px solid #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 300px;
  }
  
  label {
    font-size: 1rem;
  }
  
  input[type="text"], input[type="password"], input[type="file"] {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #333;
    color: #E0E0E0;
  }
  
  input[type="text"]::placeholder, input[type="password"]::placeholder, input[type="file"]::placeholder {
    color: #757575;
  }
  
  input[type="text"]:focus, input[type="password"]:focus, input[type="file"]:focus {
    outline: none;
    border: 1px solid #BB86FC;
  }
  
  input[type="submit"] {
    padding: 0.75rem;
    border-radius: 5px;
    border: none;
    background-color: #BB86FC;
    color: #121212;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  input[type="submit"]:hover {
    background-color: #3700B3;
  }
  
  .error {
    color: red;
    margin-top: 10px;
}
